@tailwind base;
@layer base {
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: none;
  }
}
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/Inter-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/Inter-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/Inter-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/Inter-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto Mono';
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/RobotoMono-Medium.woff2') format('woff2');
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html,
body {
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  font-weight: 500;
}

* { padding: 0; margin: 0; }
html, body, #__next {
  min-height: 100% !important;
  height: 100%;
}
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
#root,
#__next {
  isolation: isolate;
  height: 100%;
}
*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
}
.loading > svg {
  box-sizing: border-box;
  display: block;
  position: absolute;
  animation: loading 0.8s linear infinite;
  transform-origin: center;
}

.loading-big {
  width: 40px;
  height: 40px;
}
.loading-big > span {
  width: 28px;
  height: 28px;
  border-width: 4px;
}

.loading.loading-black > span {
  border-color: #000 transparent transparent transparent;
}

.loading span:nth-child(1) {
  animation-delay: -0.45s;
}

.loading span:nth-child(2) {
  animation-delay: -0.3s;
}

.loading span:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}
